// Libs
import React from 'react';

// Components
import Home from './pages/Home';

import './App.css';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
